import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Rolling Stone - Turn It Up",
  "date": "1994-06-30"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Rolling Stone - Turn It Up`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`A prerecorded MD released as a promotional item with issue 685 of
`}<a parentName="p" {...{
        "href": "https://www.rollingstone.com/"
      }}>{`Rolling Stone`}</a>{` magazine. I didn't win
the prize. I didn't even get this at the time, it came with another assortment
of MiniDiscs later.`}</p>
    <p>{`Most of the actual disc titles are all caps, in format ARTIST "TITLE"
`}<em parentName="p">{`except`}</em>{` for k.d. lang. Titles have been reformatted to mixed-case
Title (Artist) here.`}</p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol">{`Without A Trace (Soul Asylum)`}</li>
      <li parentName="ol">{`Divine Hammer (The Breeders)`}</li>
      <li parentName="ol">{`Breaking The Girl (Red Hot Chili Peppers)`}</li>
      <li parentName="ol">{`Precious Things (Tori Amos)`}</li>
      <li parentName="ol">{`These Are Days (10,000 Maniacs)`}</li>
      <li parentName="ol">{`Jimmi Diggin Cats (Digable Planets)`}</li>
      <li parentName="ol">{`Nothingness (Living Colour)`}</li>
      <li parentName="ol">{`Sweet Lullaby (Deep Forest)`}</li>
      <li parentName="ol">{`In Perfect Dreams (k.d. lang)`}</li>
      <li parentName="ol">{`Inside (Toad the Wet Sprocket)`}</li>
      <li parentName="ol">{`Sky Blue and Black (Jackson Browne)`}</li>
      <li parentName="ol">{`Bury My Lovely (October Project)`}</li>
      <li parentName="ol">{`Under My Skin (Dandelion)`}</li>
      <li parentName="ol">{`Mass Appeal (Gangstar)`}</li>
      <li parentName="ol">{`The Great Big No (The Lemonheads)`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=EWMjeCgbR2Y"
        }}>{`Promotion Message`}</a></li>
    </ol>
    <h2>{`Disc Notes`}</h2>
    <p>{`This disc is also catalogued in discogs
`}<a parentName="p" {...{
        "href": "https://www.discogs.com/release/3995087-Various-Rolling-Stone-Turn-It-Up"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      